module.exports = [{
      plugin: require('../node_modules/@whitespace/gatsby-plugin-smorgasbord-edp/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@whitespace/gatsby-plugin-page-wrapper/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@whitespace/gatsby-plugin-i18next/gatsby-browser.js'),
      options: {"plugins":[],"defaultLanguage":"sv","languages":["sv"]},
    },{
      plugin: require('../node_modules/@whitespace/gatsby-theme-wordpress-basic/gatsby-browser.js'),
      options: {"plugins":[],"basePath":"/var/lib/jenkins/workspace/minasidor.merab.stagespace.se","loginPath":"login","logoutPath":"logout","fragmentsDir":"/var/lib/jenkins/workspace/minasidor.merab.stagespace.se/src/fragments","i18next":{"defaultLanguage":"sv","languages":["sv"]},"wp":{"url":"https://cms.minasidor.merab.stagespace.se","nodesPerFetch":250},"siteIndex":{"localizations":{"sv":{"basePath":"/innehall","alphabet":["a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z","å","ä","ö"],"restInitial":{"path":"/ovriga-sidor","title":"Övriga sidor","label":"#"}}}},"postCss":{"postcssOptions":{"plugins":[null]}}},
    },{
      plugin: require('../node_modules/@municipio/gatsby-theme-basic/gatsby-browser.js'),
      options: {"plugins":[],"basePath":"/var/lib/jenkins/workspace/minasidor.merab.stagespace.se","loginPath":"login","logoutPath":"logout","fragmentsDir":"/var/lib/jenkins/workspace/minasidor.merab.stagespace.se/src/fragments","i18next":{"defaultLanguage":"sv","languages":["sv"]},"wp":{"url":"https://cms.minasidor.merab.stagespace.se","nodesPerFetch":250},"siteIndex":{"localizations":{"sv":{"basePath":"/innehall","alphabet":["a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z","å","ä","ö"],"restInitial":{"path":"/ovriga-sidor","title":"Övriga sidor","label":"#"}}}},"postCss":{"postcssOptions":{"plugins":[null]}}},
    },{
      plugin: require('../node_modules/@whitespace/gatsby-theme-smorgasbord/gatsby-browser.js'),
      options: {"plugins":[],"loginPath":"login","logoutPath":"logout","basePath":"/var/lib/jenkins/workspace/minasidor.merab.stagespace.se","fragmentsDir":"/var/lib/jenkins/workspace/minasidor.merab.stagespace.se/src/fragments","i18next":{"defaultLanguage":"sv","languages":["sv"]},"wp":{"url":"https://cms.minasidor.merab.stagespace.se","nodesPerFetch":250},"siteIndex":{"localizations":{"sv":{"basePath":"/innehall","alphabet":["a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z","å","ä","ö"],"restInitial":{"path":"/ovriga-sidor","title":"Övriga sidor","label":"#"}}}},"postCss":{"postcssOptions":{"plugins":[null]}}},
    },{
      plugin: require('../node_modules/@whitespace/gatsby-plugin-cookie-consent/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@whitespace/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"requireCookieConsent":true,"routeChangeEventName":false,"trackPageViews":true,"mtmContainerId":"container_86fStWZn","mtmHost":"https://analys.eslov.se/","includeInDevelopment":false,"mtmDefaultDataVariable":null,"mtmPAQDefaultDataVariable":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
